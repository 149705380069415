<template>
  <div class="container">

    <p class="h1 text-start mb-4"><strong>SisConMed</strong></p>

    <p class="text-start mb-4">O SisConMed é um projeto que visa automatizar os processos de consultórios médicos
      oferecendo, principalmente,
      velocidade nos processos, precisão nos faturamentos, disponibilidade de informações, flexibilidade e
      segurança.</p>


    <p class="h4 text-start">Segurança</p>


    <p class="text-start mb-4">O SisConMed utiliza um modelo atual e parametrizado de segurança, onde o
      administrador pode criar vários grupos,
      cada qual contendo seu perfil de acesso, isto é, a que módulos tem direito de acesso. Criados estes grupos,
      todos os usuários estarão relacionados a um determinado grupo, herdando todas as suas características.
    </p>

    <div class="text-start mb-4">
      <img src="../assets/Tela4.jpg" class="img-fluid rounded border" />
    </div>

    <p class="h4 text-start">Parametrização</p>


    <p class="text-start mb-4">O SisConMed está pronto para receber o cadastramento de qualquer tabela de referência
      existente (AMB, LPM por
      exemplo) ou até mesmo tabelas personalizadas, que serão utilizadas como base na hora do faturamento. Os
      planos
      são relacionados a estas tabelas, utilizando ou não um fator de ajuste, possibilitando a utilização de
      diversas
      tabelas de referência ao mesmo tempo.</p>


    <p class="text-start mb-4">Os consultórios terão pleno controle sobre o agendamento. Para isto, o SisConMed
      dispõe de módulo de
      configuração onde é informado o intervalo entre os atendimentos e o limite de cada turno. Os médicos também
      são
      relacionados a especialidades e suas disponibilidades quanto a dia da semana e turno, o que facilita o
      agendamento.</p>


    <p class="text-start mb-4">A tabela da AMB 1992 já vem parcialmente cadastada!</p>


    <p class="h4 text-start">Agendamento</p>


    <p class="text-start mb-4">O SisConMed disponibiliza, para o agendamento, os médicos e horários de acordo com o
      que foi configurado, mas
      ainda assim prevê o agendamento em horários não previstos, suprindo assim os famosos atendimentos de
      "encaixe".</p>


    <p class="h4 text-start">Atendimento</p>


    <p class="text-start mb-4">Com uma interface de fácil manuseio, este módulo apresenta não só detalhes globais do
      paciente, detalhes que
      sempre aparecerão durante os atendimentos, como também detalhes de todos os atendimentos anteriores. Também
      com
      possibilidade de digitar, imprimir e manter armazenados receituários e requisições de exames.</p>


    <p class="h4 text-start">Retaguarda</p>


    <p class="text-start mb-4">O processo de faturamento se torna mais fácil e rápido com o SisConMed. Com módulos
      para suprir qualquer
      necessidade quanto à preparação, informação de valores extras, distribuição entre a equipe e correção de
      faturas.</p>


    <p class="text-start mb-4">Também é disponibilizado um módulo para acompanhamento tanto dos recebimentos como
      dos repasses a todos os
      profissionais envolvidos.</p>


    <p class="h4 text-start">Arquitetura</p>


    <p class="text-start mb-4">O SisConMed foi desenvolvido utilizando o que há de mais comum nos computadores
      atuais. Compatível com a
      plataforma Windows utiliza o banco de dado Access.</p>


    <p class="text-start mb-4">O SisConMed também está preparado para utilização em redes, mantendo toda a
      integridade necessária para o bom
      funcionamento com uma excelente performance.</p>


    <p class="h4 text-start">Como nos preocupamos com segurança</p>

    <div class="text-start mb-4">
      <iframe src=" https://securityscorecard.com/security-rating/badge/sisconmed.com.br" width="256" height="100"
        frameBorder="0">
      </iframe>
    </div>

    <!-- <div class="container text-center">
      <div class="row align-items-center">
        <div class="col">
          <iframe src="https://securityscorecard.com/security-rating/badge/sisconmed.com.br" width="256" height="100"
            frameBorder="0">
          </iframe>
        </div>
        <div class="col">
          <a href="https://sisconmed.software.informer.com/" target="_blank">
            <img src="https://img.informer.com/awards/si-award-clean.png" alt="Software Informer Virus Free award"
              height="170" width="170" />
          </a>
        </div>
      </div>
    </div> -->

  </div>
</template>

<script>
export default {

}
</script>

<style></style>
