import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { createApp } from 'vue'
import VueGtag from 'vue-gtag'
import App from './App.vue'
import router from './router'

const app = createApp(App)
app.use(router)

app.use(VueGtag, {
  appName: 'SisConMed',
  pageTrackerScreenviewEnabled: true,
  config: {
    id: 'G-QPM13BFRR3',
  },
}, router)

app.mount('#app')

import 'bootstrap/dist/js/bootstrap.js'
