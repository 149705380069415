import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: "download" */ '../views/DownloadView.vue')
  },
  {
    path: '/conhecendo',
    name: 'conhecendo',
    component: () => import(/* webpackChunkName: "conhecendo" */ '../views/ConhecendoView.vue')
  },
  {
    path: '/sistema',
    name: 'sistema',
    component: () => import(/* webpackChunkName: "sistema" */ '../views/SistemaView.vue')
  },
  {
    path: '/cadastro',
    name: 'cadastro',
    component: () => import(/* webpackChunkName: "cadastro" */ '../views/CadastroView.vue')
  },
  {
    path: '/atendimento',
    name: 'atendimento',
    component: () => import(/* webpackChunkName: "atendimento" */ '../views/AtendimentoView.vue')
  },
  {
    path: '/administracao',
    name: 'administracao',
    component: () => import(/* webpackChunkName: "administracao" */ '../views/AdministracaoView.vue')
  },
  {
    path: '/relatorio',
    name: 'relatorio',
    component: () => import(/* webpackChunkName: "relatorio" */ '../views/RelatorioView.vue')
  },
  {
    path: '/configuracao',
    name: 'configuracao',
    component: () => import(/* webpackChunkName: "configuracao" */ '../views/ConfiguracaoView.vue')
  },
  {
    path: '/faleconosco',
    name: 'faleconosco',
    component: () => import(/* webpackChunkName: "faleconosco" */ '../views/FaleConoscoView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'home' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
