<template>
  <NavBar />
  <router-view class="mb-5" />
</template>

<script>
import NavBar from '@/components/NavBar.vue'

export default {
  components: {
    NavBar
  }
}
</script>

<style></style>
