<template>
  <nav class="navbar navbar-expand-lg sticky-top navbar-dark bg-success mb-4">
    <div class="container-fluid">
      <span class="navbar-brand"><strong>SisConMed</strong></span>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="bi-justify"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" aria-current="" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" aria-current="" to="/download">Download</router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Tutorial
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <router-link class="dropdown-item" to="/conhecendo">Conhecendo</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/sistema">Sistema</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/cadastro">Cadastros</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/atendimento">Atendimentos</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/administracao">Administração</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/relatorio">Relatórios</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" to="/configuracao">Configuração</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" aria-current="" to="/faleconosco">Fale Conosco</router-link>
          </li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="https://www.arktel.com.br/" target="_blank">ARKTEL</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar'
}
</script>

<style>
</style>
